import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, Link } from 'gatsby-plugin-intl'
import Fade from 'react-reveal/Fade'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import { CASES_URL } from '_utils/config'
import { Layout } from '_templates'
import { Arrow, BlueText, Container, Heading, Text } from '_atoms'
import { Hero, QuoteContainer, ProudCases } from '_molecules'
import { SEO } from '_organisms'
import Chat from '_images/svgs/chat.inline.svg'

import styles from './styles.module.css'

const PortfolioPage = ({ data }) => {
  const { portfolioTitle, portfolioDescription, portfolioThumbnail } = data.contentfulPageMetatags
  const caseStudies = data.allContentfulCaseStudy.nodes
  const testimonials = data.allContentfulPortfolioTestimonials.nodes
  const templatedUseCases = data.allContentfulUseCasePortfolioTemplate.edges

  const caseStudiesWithHref = caseStudies.map(caseStudy => ({
    ...caseStudy,
    href: CASES_URL[caseStudy.projectName],
  }))

  const templatedUseCasesWithHref = templatedUseCases.map(({ node }) => ({
    ...node,
    href: `/portfolio/${node.slug}/`,
  }))

  return (
    <Layout>
      <SEO
        title={portfolioTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={portfolioDescription || 'Web &amp; Mobile App Design and Development Company'}
        thumbnail={portfolioThumbnail.file.url}
      />
      <Hero title="portfolio.hero.title" subTitle="portfolio.hero.subtitle" />

      <section className={styles.mainCases}>
        <Container>
          <Heading type="h2" bold className={styles.heading}>
            <FormattedMessage id="portfolio.caseStudies" />
            <BlueText squared>.</BlueText>
          </Heading>

          <div className={styles.mainCasesGrid}>
            {[...templatedUseCasesWithHref, ...caseStudiesWithHref].map(
              ({ id, title, main, projectColor, projectName, thumbnail, href }) =>
                main && (
                  <Link
                    to={href}
                    key={id}
                    style={{ backgroundColor: projectColor }}
                    className={styles.mainCasesItem}
                  >
                    <div className={styles.mainCasesContent}>
                      <Text color="white" size="32" className={styles.mainCasesTitle}>
                        {title}
                      </Text>
                      <Text color="white" size="18" className={styles.mainCasesProject}>
                        {projectName}
                      </Text>
                    </div>
                    <div className={styles.mainCasesThumbnail}>
                      <Fade distance="25%" bottom>
                        <Img
                          fluid={thumbnail.fluid}
                          alt={thumbnail.description}
                          imgStyle={{
                            position: 'relative',
                            height: '100%',
                            width: 'auto',
                            objectFit: 'contain',
                            margin: 'auto',
                            objectPosition: 'bottom center',
                          }}
                        />
                      </Fade>
                    </div>
                  </Link>
                )
            )}
          </div>
        </Container>
      </section>

      <section className={styles.testimonialsSection}>
        <QuoteContainer
          items={testimonials}
          isDark
          isMultiple
          className={styles.quoteContainer}
          sliderClassName={styles.testimonialsSectionSlider}
          iconsClass={styles.testimonialsSectionIcons}
          isCenter
          commaFormat
          hideUserPhotoMobile
        />
      </section>

      <ProudCases
        caseStudies={caseStudies}
        showCTA={false}
        mainCasesOnly
        hasItemHover={false}
        hasCaseLink={false}
      />

      <section className={styles.chat}>
        <Container className={styles.chatContainer}>
          <Link to="/contact" className={styles.chatText} id="servicesChatAboutProject">
            <Text size="56" color="dark" className={styles.text}>
              <FormattedMessage id="portfolio.chat.text" />
              <br />

              <strong>
                <FormattedMessage id="portfolio.chat.bold" />
              </strong>
            </Text>

            <Arrow className={styles.chatArrow} />
          </Link>

          <Link to="/contact" className={styles.chatTextMobile} id="mobileChatAboutYourIdea">
            <Text color="dark" className={styles.text}>
              <span>
                <FormattedMessage id="portfolio.chat.textMobile" />
              </span>{' '}
              <strong>
                <FormattedMessage id="portfolio.chat.boldMobile" />
                <span className={styles.squared}>?</span>
              </strong>
            </Text>

            <Arrow className={styles.chatArrow} />
          </Link>

          <Chat className={styles.chatIllustration} />
        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query PortfolioPageContent($locale: String) {
    contentfulPageMetatags(fields: { localeKey: { eq: $locale } }) {
      portfolioDescription
      portfolioTitle
      portfolioThumbnail {
        file {
          url
        }
      }
    }
    allContentfulUseCasePortfolioTemplate(filter: { fields: { localeKey: { eq: $locale } } }) {
      edges {
        node {
          id
          slug
          title
          projectName
          projectColor
          main
          thumbnail {
            description
            fluid {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allContentfulCaseStudy(
      filter: { fields: { localeKey: { eq: $locale } } }
      sort: { fields: order }
    ) {
      nodes {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fluid {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
    allContentfulPortfolioTestimonials(filter: { fields: { localeKey: { eq: $locale } } }) {
      nodes {
        jobDescription
        id
        text
        user
        userImage {
          fixed(width: 64, height: 64, quality: 80) {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
        }
      }
    }
  }
`

PortfolioPage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default PortfolioPage
